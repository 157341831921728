import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Select, Modal, Input } from 'antd';
import { capitalize } from '../../helpers/utils';
import { IoIosArrowDown } from 'react-icons/io';
import { setMacronutrientAnswers } from '../../ducks/Global/actions';
import { useDispatch, useSelector } from 'react-redux';
import { generateMealPlan } from '../../helpers/utils';

export const MacroResults = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cardOpen, setCardOpen] = useState({
    bmr: false,
    tdee: false,
    macros: false,
    water: false,
  });
  const macronutrientAnswers = useSelector(
    (state) => state.global.macronutrientAnswers
  );
  const [percentageError, setPercentageError] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [mealPlanAnswers, setMealPlanAnswers] = useState({
    numberOfMeals: 3,
    dietType: 'Standard',
    largestMeal: 'Distribute equally',
  });
  const [macroResults, setMacroResults] = useState(null);
  const [selectedMacros, setSelectedMacros] = useState({});

  const { results = {}, isMobileView } = location.state || {};

  useEffect(() => {
    setMacroResults(results);
  }, [results]);

  useEffect(() => {
    if (!macroResults || !macroResults.macrosForEachGoal) return;

    const selectedGoal = Object.entries(macroResults.macrosForEachGoal).find(
      ([, goal]) => goal.isSelected
    );

    if (selectedGoal) {
      const [key, goal] = selectedGoal;
      setSelectedMacros({
        goal: capitalize(key.split('-').join(' ')),
        protein: goal.protein,
        carbs: goal.carbs,
        fat: goal.fat,
        calories: goal.calories,
      });
    }
  }, [macroResults, setSelectedMacros]);

  if (!results.bmr) {
    return <p>Loading results...</p>;
  }

  console.log(selectedMacros);

  const items = [
    {
      value: 'balanced',
      label: t('tabs.balanced.label'),
      explanation: t('tabs.balanced.explanation'),
    },
    {
      value: 'low-carb',
      label: t('tabs.low-carb.label'),
      explanation: t('tabs.low-carb.explanation'),
    },
    {
      value: 'high-carb',
      label: t('tabs.high-carb.label'),
      explanation: t('tabs.high-carb.explanation'),
    },
    {
      value: 'high-protein',
      label: t('tabs.high-protein.label'),
      explanation: t('tabs.high-protein.explanation'),
    },
    {
      value: 'ketogenic',
      label: t('tabs.ketogenic.label'),
      explanation: t('tabs.ketogenic.explanation'),
    },
    {
      value: 'custom',
      label: t('tabs.custom.label'),
      explanation: t('tabs.custom.explanation'),
    },
  ];

  const mealsPerDay = [
    {
      key: 3,
      value: 3,
    },
    {
      key: 4,
      value: 4,
    },
    {
      key: 5,
      value: 5,
    },
    {
      key: 6,
      value: 6,
    },
  ];

  const dietType = [
    {
      key: 1,
      value: 'Standard',
    },
    {
      key: 2,
      value: 'Vegetarian',
    },
  ];

  const largestMeal = [
    {
      key: 1,
      value: 'Distribute equally',
    },
    {
      key: 2,
      value: 'Breakfast',
    },
    {
      key: 3,
      value: 'Lunch',
    },
    {
      key: 4,
      value: 'Dinner',
    },
  ];

  const setCustomMacros = (macroType, percentage) => {
    const updatedMacros = {
      ...macronutrientAnswers.customMacros,
      [macroType]: percentage,
    };

    // Calculate the remaining macro percentage
    const totalPercentage =
      updatedMacros.protein + updatedMacros.carbs + updatedMacros.fat;

    setPercentageError(totalPercentage !== 100);

    dispatch(
      setMacronutrientAnswers({
        ...macronutrientAnswers,
        customMacros: updatedMacros,
      })
    );

    setMacroResults((prevResults) => ({
      ...prevResults,
      macrosForEachGoal: Object.fromEntries(
        Object.entries(prevResults.macrosForEachGoal).map(([key, goal]) => [
          key,
          {
            ...goal,
            ...calculateMacrosWithCustomValues(goal.calories, updatedMacros),
          },
        ])
      ),
    }));
  };

  const calculateMacrosWithCustomValues = (calories, customMacros) => {
    return {
      protein: Math.round(((customMacros.protein / 100) * calories) / 4),
      carbs: Math.round(((customMacros.carbs / 100) * calories) / 4),
      fat: Math.round(((customMacros.fat / 100) * calories) / 9),
    };
  };

  const renderCustomMacrosInput = () => {
    if (macronutrientAnswers.isCustomMacros) {
      return (
        <div className='mb-20'>
          <div className='custom-macros-input-container'>
            {Object.entries(macronutrientAnswers.customMacros).map((macro) => (
              <Input
                onChange={(e) =>
                  setCustomMacros(macro[0], Number(e.target.value))
                }
                value={macronutrientAnswers.customMacros[macro[0]]}
                type='number'
                addonAfter={`${capitalize(macro[0])} %`}
                maxLength={2}
              />
            ))}
          </div>
          {percentageError ? (
            <p
              className='mb-20'
              style={{ textAlign: 'center', color: 'red', fontSize: '14px' }}
            >
              Macro percentages must equal 100%
            </p>
          ) : null}
        </div>
      );
    }
  };

  const setMacroPreference = (value) => {
    if (!macroResults) return;

    const calculateMacros = (
      calories,
      proteinPercent,
      carbPercent,
      fatPercent
    ) => ({
      protein: Math.round((proteinPercent * calories) / 4),
      carbs: Math.round((carbPercent * calories) / 4),
      fat: Math.round((fatPercent * calories) / 9),
    });

    // Update macros for each goal based on the selected preference
    const updatedMacrosForEachGoal = Object.fromEntries(
      Object.entries(macroResults.macrosForEachGoal).map(([key, goal]) => {
        const calories = goal.calories;
        let updatedGoal;

        switch (value) {
          case 'balanced':
            updatedGoal = calculateMacros(calories, 0.35, 0.35, 0.3);
            break;
          case 'low-carb':
            updatedGoal = calculateMacros(calories, 0.4, 0.2, 0.4);
            break;
          case 'high-carb':
            updatedGoal = calculateMacros(calories, 0.3, 0.5, 0.2);
            break;
          case 'high-protein':
            updatedGoal = calculateMacros(calories, 0.4, 0.3, 0.3);
            break;
          case 'ketogenic':
            updatedGoal = calculateMacros(calories, 0.4, 0.1, 0.5);
            break;
          case 'custom':
            updatedGoal = calculateMacros(
              calories,
              macronutrientAnswers.customMacros.protein / 100,
              macronutrientAnswers.customMacros.carbs / 100,
              macronutrientAnswers.customMacros.fat / 100
            );
            break;
          default:
            updatedGoal = calculateMacros(calories, 0.35, 0.35, 0.3);
            break;
        }

        return [
          key,
          {
            ...goal,
            ...updatedGoal,
          },
        ];
      })
    );

    // Update both macroResults and macronutrientAnswers
    setMacroResults((prevResults) => ({
      ...prevResults,
      macrosForEachGoal: updatedMacrosForEachGoal,
    }));

    // Dispatch updated macronutrientAnswers to reflect selected preference
    dispatch(
      setMacronutrientAnswers({
        ...macronutrientAnswers,
        macroPreference: value,
        isCustomMacros: value === 'custom',
      })
    );
  };

  const renderSelectedGoalAndMacros = () => {
    return (
      macroResults &&
      Object.entries(macroResults.macrosForEachGoal).map(([key, goal]) => {
        return (
          goal.isSelected && (
            <div
              className='global-card dark'
              style={{
                border: '3px solid gold',
                padding: '18px',
                borderRadius: '20px',
              }}
            >
              <div className='flex space-between'>
                <p className='eyebrow-text mb-10' style={{ color: '#2761f1' }}>
                  {capitalize(key.split('-').join(' '))}
                </p>
              </div>
              <div className='flex space-between'>
                <div className='grid justify-center'>
                  <p className='macro-number bold'>{goal.calories}</p>
                  <p className='subtitle'>Calories</p>
                </div>
                <div className='grid justify-center'>
                  <p className='macro-number bold'>{goal.protein}g</p>
                  <p className='subtitle'>Protein</p>
                </div>
                <div className='grid justify-center'>
                  <p className='macro-number bold'>{goal.carbs}g</p>
                  <p className='subtitle'>Carbs</p>
                </div>
                <div className='grid justify-center'>
                  <p className='macro-number bold'>{goal.fat}g</p>
                  <p className='subtitle'>Fat</p>
                </div>
              </div>
            </div>
          )
        );
      })
    );
  };

  const mealPlanQuestions = [
    {
      label: 'Preferred meals per day',
      question: 'numberOfMeals',
      defaultValue: 3,
      options: mealsPerDay,
    },
    {
      label: 'Diet type',
      question: 'dietType',
      defaultValue: 'Standard',
      options: dietType,
    },
    {
      label: 'Largest meal of the day',
      question: 'largestMeal',
      defaultValue: 'Distribute equally',
      options: largestMeal,
    },
  ];

  const handleGenerateMealPlan = () => {
    let mealPlan = generateMealPlan(
      selectedMacros,
      mealPlanAnswers.dietType,
      mealPlanAnswers.numberOfMeals,
      mealPlanAnswers.largestMeal
    );

    navigate('/tools/macronutrient-calculator/results/custom-meal-plan', {
      state: { mealPlan }, // Pass the state as an object with the `state` key
    });
  };

  return (
    <div className='macro-results-page'>
      <Modal
        maskStyle={{ backgroundColor: 'black', opacity: '0.8' }}
        className='dark'
        open={modalVisible}
        footer={null}
        closable={true}
        onCancel={() => setModalVisible(false)}
      >
        {/* <p className='macro-number mb-20'>Let's get your meal plan ready!</p> */}
        <div className='mb-20 mt-10'>{renderSelectedGoalAndMacros()}</div>
        {mealPlanQuestions.map((question) => (
          <div>
            <p className='label'>{question.label}</p>
            <Select
              className='mb-20'
              options={question.options}
              defaultValue={question.defaultValue}
              value={mealPlanAnswers[question.question].value}
              style={{
                width: '100%',
                border: '1px solid transparent',
              }}
              onChange={(value) =>
                setMealPlanAnswers({
                  ...mealPlanAnswers,
                  [question.question]: value,
                })
              }
            />
          </div>
        ))}
        <Button
          type='primary'
          size='large'
          className='landing-button full-width-button gradient'
          onClick={() => handleGenerateMealPlan()}
        >
          Generate Your Meal Plan
        </Button>
      </Modal>

      {macroResults && (
        <div className='landing-page black-background'>
          <div className='results-container'>
            <div className='grid justify-center mb-50'>
              <p className='small-title text-center text-gradient'>
                {t('results.congrats')}
              </p>
            </div>
            <div
              className='global-card dark clickable mb-10'
              onClick={() =>
                setCardOpen({ ...cardOpen, bmr: !cardOpen['bmr'] })
              }
            >
              <div className='flex space-between align-center'>
                <p className='eyebrow-text mb-10' style={{ color: '#2761f1' }}>
                  Your BMR (Basal Metabolic Rate)
                </p>
                <IoIosArrowDown className='mb-10' />
              </div>
              <p
                className='macro-number bold'
                style={{ marginBottom: cardOpen['bmr'] && '10px' }}
              >
                {macroResults.bmr}
              </p>
              {cardOpen['bmr'] && (
                <p className='subtitle'>
                  BMR represents the number of calories your body needs to
                  perform basic functions like breathing, circulation, and cell
                  production while at rest. It's the minimum amount of energy
                  required to sustain life, and it varies based on factors like
                  age, weight, gender, and muscle mass.
                </p>
              )}
            </div>

            <div
              className='global-card dark clickable mb-10'
              // style={{ background: '#2761f1' }}
              onClick={() =>
                setCardOpen({ ...cardOpen, tdee: !cardOpen['tdee'] })
              }
            >
              <div className='flex space-between align-center'>
                <p className='eyebrow-text mb-10' style={{ color: '#2761f1' }}>
                  Your TDEE (Total Daily Energy Expenditure)
                </p>
                <IoIosArrowDown />
              </div>
              <p
                className='macro-number bold'
                style={{ marginBottom: cardOpen['tdee'] && '10px' }}
              >
                {macroResults.maintenanceCalories}
              </p>
              {cardOpen['tdee'] && (
                <p className='subtitle'>
                  TDEE is the total number of calories your body burns in a day,
                  including all physical activities such as exercise, walking,
                  and even digestion. It combines your BMR with your activity
                  level to determine how many calories you need to maintain your
                  current weight.
                </p>
              )}
            </div>

            <div className='global-card dark mb-10'>
              <div className='mb-20'>
                <p className='eyebrow-text' style={{ color: '#2761f1' }}>
                  Your Macros
                </p>
              </div>

              <div className='mb-20'>
                <div className='grid gap-10'>
                  {Object.entries(macroResults.macrosForEachGoal)
                    .sort(([, goalA], [, goalB]) =>
                      goalA.isSelected === goalB.isSelected
                        ? 0
                        : goalA.isSelected
                        ? -1
                        : 1
                    )
                    .map(([key, goal]) => (
                      <>
                        <div
                          key={key}
                          className='global-card dark'
                          style={{
                            position: 'relative',
                            border: goal.isSelected && '3px solid gold',
                            padding: '18px',
                            borderRadius: '20px',
                          }}
                        >
                          {goal.isSelected && (
                            <span
                              className='yellow-label recommended'
                              style={{ width: isMobileView && '100%' }}
                            >
                              Recommended for you
                            </span>
                          )}
                          <div>
                            <div className='grid'>
                              <p
                                className='eyebrow-text mb-10'
                                style={{ color: '#2761f1' }}
                              >
                                {capitalize(key.split('-').join(' '))}{' '}
                                {`${key !== 'maintain-weight' ? '~' : ''}`}
                                {goal.description}
                              </p>
                            </div>

                            <div className='flex space-between'>
                              <div className='grid justify-center'>
                                <p className='macro-number bold'>
                                  {goal.calories}
                                </p>
                                <p className='subtitle'>Calories</p>
                              </div>
                              <div className='grid justify-center'>
                                <p className='macro-number bold'>
                                  {goal.protein}g
                                </p>
                                <p className='subtitle'>Protein</p>
                              </div>
                              <div className='grid justify-center'>
                                <p className='macro-number bold'>
                                  {goal.carbs}g
                                </p>
                                <p className='subtitle'>Carbs</p>
                              </div>
                              <div className='grid justify-center'>
                                <p className='macro-number bold'>{goal.fat}g</p>
                                <p className='subtitle'>Fat</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              </div>
            </div>
            <div className='global-card dark mb-10'>
              <div className='mb-20'>
                <p className='eyebrow-text' style={{ color: '#2761f1' }}>
                  Your Diet Type
                </p>
                <p className='subtitle'>
                  Select your preferred diet type to further personalize your
                  macronutrients.
                </p>
              </div>
              <div>
                <Select
                  className='mb-20'
                  options={items}
                  defaultValue='balanced'
                  style={{
                    width: 300,
                    border: '1px solid transparent',
                  }}
                  onChange={(value) => setMacroPreference(value)}
                  value={macronutrientAnswers.macroPreference || null}
                />
                <p className='subtitle mb-20'>
                  {
                    items.find(
                      (item) =>
                        item.value === macronutrientAnswers.macroPreference
                    ).explanation
                  }
                </p>
              </div>
              {renderCustomMacrosInput()}
              {renderSelectedGoalAndMacros()}
            </div>
            <div
              className='global-card dark clickable mb-10'
              onClick={() =>
                setCardOpen({ ...cardOpen, bmr: !cardOpen['bmr'] })
              }
            >
              <p className='eyebrow-text' style={{ color: '#2761f1' }}>
                Your Nutrition Plan
              </p>
              <p className='subtitle mb-20'>
                When everything looks good, generate your custom meal plan.
              </p>
              <div>
                <Button
                  onClick={() => setModalVisible(true)}
                  size='large'
                  type='primary'
                  className='landing-button gradient'
                >
                  Generate Your Meal Plan
                </Button>
              </div>
            </div>
            <div className='flex justify-center'>
              <Button
                type='primary'
                className='landing-button mt-30'
                onClick={() => navigate('/tools/macronutrient-calculator')}
              >
                {t('buttons.calculateAgain')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
