import React from 'react';
import { useLocation } from 'react-router-dom';
import { capitalize } from '../../helpers/utils';
import { LuDot } from 'react-icons/lu';
import './index.css';

export const CustomMealPlan = () => {
  const location = useLocation();
  const customMealPlan = location.state?.mealPlan;

  const renderMealPlan = () => {
    return (
      <div>
        {customMealPlan.mealPlan.map((plan, index) => (
          <div
            className='global-card dark mb-10'
            style={{ padding: '19px' }}
            key={index}
          >
            <p className='eyebrow-text blue mb-10'>{capitalize(plan.name)}</p>
            <div className='macro-meal-labels'>
              {Object.entries(plan.macros).map((macro) => (
                <div className='flex yellow-label'>
                  <p
                    style={{ fontSize: '14px', fontWeight: 'bold' }}
                    className='mr-5 '
                  >
                    {macro[1]}
                    {`${macro[0] !== 'calories' ? 'g' : ''}`}
                  </p>
                  <p style={{ fontSize: '14px', fontWeight: 'bold' }}>
                    {capitalize(macro[0])}
                  </p>
                </div>
              ))}
            </div>
            {plan.foods.map((food, foodIndex) => (
              <div className='flex align-center' key={foodIndex}>
                <LuDot style={{ color: 'blue' }} />
                <div>
                  {food.servingSize} {food.servingType} - {food.name}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className='landing-page black-background'>
      <div className='results-container'>
        <div className='grid justify-center mb-50'>
          <p className='small-title white text-center text-gradient'>
            Your Custom Meal Plan
          </p>
        </div>
        {renderMealPlan()}
      </div>
    </div>
  );
};
